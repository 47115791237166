.home{
    display: flex;

    .homeCont{
        flex: 6;
        
        .content{
            display: flex;
            width: 100%;
            .widgets{
                display: flex;
                padding: 20px;
                gap: 20px;
                flex: 1;
            }
            .charts-page{
                display: flex;
                width: 100%;
                justify-content: center;
                flex-direction: column;
                padding: 20px;
                flex: 1;
            }
        }
    }
}