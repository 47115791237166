.paginaGrandesEncomendas{
    display: flex;
    
    .contGrandesEncomendas{
        flex: 6;
        
        .content{
            display: flex;
            justify-content: center; 
            flex-direction: column;
            margin: 40px 40px 20px 60px;

            .tabela-cargo{
                width: 100%;
                display: flex;
                justify-content: flex-start; 
                align-items: flex-start;
                flex-direction: row;

            }
            
            .cards{
                margin-top: 20px;
                display: flex;
                justify-content: center;
                width: 100%;
                height: 100%;
            }

            .nome-funcionario{
                font-size: 15px;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                width: 100%;
                color: rgb(78, 78, 78);
            }
            .botoes-filtro{
                margin-top: 15px;
                margin-left: 20px;
                font-size: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                color: rgb(78, 78, 78);

                .botao-baixar{
                    width: 100%;
                    display: flex;
                    margin-right: 25px;
                    justify-content: flex-end;
                    color: #1976d2;
                    cursor: pointer;
                }

                .botao{
                    padding: 10px;
                    border-bottom: 2px solid #6B4380;
                    margin-left: 5px;
                    cursor: pointer;
                }

                .botao-atualiza{
                    display: flex;
                    align-items: center;
                    margin-right: 25px;
                    .toggle_on{
                        font-size: 30px;
                        color: rgb(66, 175, 60);
                        cursor: pointer;
                    }
                    .toggle_off{
                        font-size: 30px;
                        cursor: pointer;
                    }
                }
            }

            .tituloGrandesEncomendas{
                font-size: 20px;
                display: flex;
                color: rgb(78, 78, 78);

               .iconTitulo{
                 color: #6B4380;
                 margin-right: 5px;
               }
            }

            .botaoGrandesEncomendas{
                display: flex;
                width: 100%;
                justify-content: flex-end;
                align-items: flex-end;
                color: #6B4380;
                cursor: pointer;

                .iconAddEntrega{
                    font-size: 30px;
                }
            }


            .widgets{
                display: flex;
                padding: 20px;
                gap: 20px;
                flex: 1;
            }

            .popUpConteudoCamera{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .sec-assinatura{
                    margin-left: 5px;
                    margin-top: 10px;
                }
            }
    
        }
    }

    .popUp-infos-linha{
        padding-right: 15px;
        .titulo-entrega{
            font-size: 25px;
            color:#6B4380; 
            margin-bottom: 20px;
            margin-left: 10px;
        }

    }

    .popUp-filtro-cargo{

        .title-filter{
            font-size: 25px;
            color:#6B4380; 
            margin-bottom: 30px;
        }

        .loader{
            top: 50%;
            left: 0;
            width: 100%;
            height: 70px;
            justify-content: center;
            display: flex;
            position: fixed;
        }

        .itens-filtro{
            .formulario-filtro{
                margin-bottom: 20px;
            }

            .select-cargo{
                margin-bottom: 20px;
            }

            .filtro-data{
                margin-bottom: 20px;
                margin-left: 10px;
                color: rgb(78, 78, 78);
            }

            .botao-filtro-cargo{
                display: flex;
                justify-content: flex-end;
            }
        }
    }


}