@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,100&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,700&display=swap');
.ocorrencia {
  display: flex;
  width: 100%;
  .ocorrenciaCont {
    flex: 6;
    .content{
      display: flex;
    }
 
    .menu {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin: 10px 60px 10px 50px;
      width: 500px;
      height: 40px;
      border: 1px solid rgb(179, 179, 179);
      border-radius: 2px;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%);
      .left {
        display: flex;
        border-right: 0.5px solid rgb(179, 179, 179);
      }
      .right {
        display: flex;
        border-left: 0.5px solid rgb(179, 179, 179);
      }
      .colorWheel {
        position: absolute;
        background-color: #ffffff;
        box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px;

        top: 38px;
        left: 45px;
        z-index: 99;
        .colorOpts {
          display: flex;
          padding: 0px 10px 0px 10px;
          border-radius: 2px;

          background-color: rgb(236, 236, 236);

          justify-content: space-around;
        }
      }
      .newLaneForm {
        position: absolute;
        display: flex;
        padding: 8px;
        top: 37px;
        left: -41px;
        background-color: #e9e9e9;
        border-radius: 5px;
        border: 0.5px solid rgb(122, 122, 122);
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.137);

        z-index: 99;
        .inputs {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .input {
            flex: 1;
          }
        }
        .buttons {
          display: flex;
        }
      }
      .toolItem {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        cursor: pointer;
        z-index: 99;

        &:hover {
          background-color: #d4d4d4;
        }
      }
    }
    .react-trello-board {
      padding-top: 55px;
      height: fit-content;
      background-color: rgb(255, 255, 255);
      font-family: 'Roboto', sans-serif;

    }
  }

}
