.conhecimento {
  display: flex;
  width: 100%;

  .conhecimentoCont {
    flex: 6;

    .box {
      margin: 10px;
      align-items: center;
      border-radius: 2px;
      border: 1px solid rgb(179, 179, 179);

      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%);
    }
    .toolItem {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 3px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      cursor: pointer;
      z-index: 99;

      &:hover {
        background-color: #d4d4d4;
      }
    }
    .menuList {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin: 10px 60px 10px 50px;
      width: 500px;
      height: 40px;
      border: 1px solid rgb(179, 179, 179);
      border-radius: 2px;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%);
      .search {
        display: flex;
        align-items: center;
        margin: 4px;
        border: 0.5px solid lightgray;
        padding: 3px;
        cursor: pointer;

        input {
          border: none;
          outline: none;
          background: transparent;

          &::placeholder {
            font-size: 14px;
          }
        }

        &:hover {
          color: #7451f8;
        }
      }
      .left {
        display: flex;
        border-right: 0.5px solid rgb(179, 179, 179);
      }
      .right {
        display: flex;
        border-left: 0.5px solid rgb(179, 179, 179);
      }
    }
    .newKb {
      display: flex;
      margin: 10px;
      input,
      textarea {
        caret-color: black;
        outline: none;
      }
      .makeNew {
        display: flex;
        padding: 10px;
        border: 1px solid rgb(179, 179, 179);

        .inputs {
          display: flex;
          flex-direction: column;
          width: 400px;
        }
        .kbContent {
          font-size: 15px;
          height: 250px;
        }
      }
      .preview {
        margin-left: 10px;

        width: 100%;
        border: 1px solid rgb(179, 179, 179);
        textarea {
          border: none;
          width: 400px;
        }
      }
    }
    .kbList {
      display: flex;
      flex-direction: column;
      margin: 10px;

      .kbListItem {
        display: flex;
        align-items: center;
        margin: 5px 0px 5px 0px;
        padding: 5px;
        border: 1px solid rgb(179, 179, 179);
        cursor: pointer;

        .icons {
          padding: 0px 10px 0px 10px;
          margin-right: 5px;
        }
        &:hover {
          background-color: #d4d4d4;
        }
      }
    }
  }
}
