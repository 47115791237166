.datePicker {
    display: flex;
    align-items: center;
    padding: 10px;
    .newDates {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .clickable{
            .date-clicavel{
                cursor: pointer;
                border-radius: 5px;
                border: 1px solid #BCB0B0;
                color: rgb(78, 78, 78);

            }
            .react-datepicker {
                display: flex;
                cursor: pointer;
              }
        }
      }
    .pickers {
      position: absolute;
      width: 200px;
      top: 50px;
      left: -60px;
      padding: 8px;
      border-radius: 4px;
      display: flex;
      background-color: #dddddd;
      flex-direction: column;
      align-items: center;
      z-index: 999;
      .label {
        padding-right: 5px;
      }

      .dates {
        display: flex;
        padding: 2px;
      }
    }


  }