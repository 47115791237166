.home{
    display: flex;

    .homeCont{
        flex: 6;
        
        .content{
            display: flex;
            justify-content: center; //cetraliza verticalment

            .widgets{
                display: flex;
                padding: 20px;
                gap: 20px;
                flex: 1;
            }
        }
    }
}