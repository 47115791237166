.tabela-nova{
    width: 100%;
    background: #ffffff;
    padding: 0px 0px 0px 0px;
    margin: 5px;
    background-color: transparent;
    font-family: 'Gabarito', sans-serif;
    .head{
        font-family: 'Gabarito', sans-serif;
        font-size: 15px;
        color: rgb(78, 78, 78);
        background-color: transparent;
        border-bottom: 1px solid #F2F2F2;
        .th{
            background-color: transparent;
            border-bottom: 1px solid #F2F2F2;
        }
    }
    .td{
        font-family: 'Gabarito', sans-serif;
        font-size: 15px;
        color: rgb(78, 78, 78);
        text-align: center;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #F2F2F2;
        .material-icons{
            color:  rgb(107, 67, 128);
            cursor: pointer;
            
        }
        .box{
            border-radius: 7px;
        }

    }
}

.buttons {
    display: flex;
    justify-content: flex-end;
}

.countSelected{
    align-self: center;
    font-size: 15px;
    margin-left: 25px;
    color: rgb(78, 78, 78);
}