.main{
    .retornosCont{
        width: 100%;
        .content{
            display: flex;
            width: 100%;
        
            .tela-principal-grandes-encomendas{
                display: flex;
                padding: 40px 20px 20px 20px;
                flex-direction: column;
                color:#6B4380;
                position: relative;
                max-height: 800px;
                overflow-y: auto;
                width: 100%;
            
                .titulo-grandes-entregas{
                    display: flex;
                    height: auto;
                    width: 100%;
                    margin-bottom: 15px;
            
                    .texto-titulo-Grandes-encomendas{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 25px;
                        color: #5a595c;
                    }
                }
            
                .botao-coleta{
                    display: flex;
                    height: auto;
                    margin: auto 0px auto auto;
                    justify-content: flex-end;
                }
            
                .menu-status-entregas{
                    display: flex;
                    justify-content: center;
                    margin: 5px 0px 20px 30px;
            
                    .lista-grades-entregas{
                        margin-top: 15px;
                        width: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0px 10px 0px 10px;
                    }
                }
                
                .table-title{
                    th{
                        color: rgb(78, 78, 78);
                    }
                }
            
                @media (max-width: 700px) {
                    .menu-status-entregas{
                        flex-direction: column;
                        margin: 5px;
                    }
            
                }
            }
        }
    }

    .tabs-container {
        position: absolute;
        right: 20px;
    }
      
}