.tabelas-comando{
    padding: 20px;

    width: 1000px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .barra{
        overflow-y: hidden;
    }

    .barra:hover {
        overflow-y: auto;
    }

    .telas-acoes{
        display: flex;
        width: 100%;
        justify-content: center;

        .tabelas-conteudo{
            margin-right: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .Desativado{
                opacity: 0.6; 
                transition: opacity 0.3s ease;
                
            }
            

            .tabela-limite{
                display: flex;
                align-items: center;
            }

            .imagem{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

            }
        }
    }

    .loader{

        top: 50%;
        left: 0;
        width: 100%;
        height: 70px;
        justify-content: center;
        display: flex;
        position: fixed;
    }

    .painel-controle{
        font-family: 'Gabarito', sans-serif;
        font-size: 20px;
        color: rgb(78, 78, 78);
        font-weight: bold;
        margin-bottom: 40px;

        display: flex;
        margin-left: 40px;
    }
    .ativacao-motoristas{
        display: flex;
        flex-direction: column;
        align-items: center;
        .conteudo{
            padding: 15px;
            border: 1px solid #BCB0B0;
            border-radius: 8px;
        
            .titulo{
                font-family: 'Gabarito', sans-serif;
                font-size: 17px;
                color: rgb(78, 78, 78);
                font-weight: bold;

                display: flex;
                justify-content: center;

            }
            .opcoes{
                margin-left: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .coteudo-boxs{
                    .grupo-box{
                        display: flex;
                        margin: 5px;


                        .box-tela-comado{
                            margin-right: 20px ;
                            font-family: 'Gabarito', sans-serif;
                            font-size: 15px;
                            color: rgb(78, 78, 78);
                        }
                    }
                }
            }
            .botao{
                display: flex;
                justify-content: center;
            }
        }
    }
}