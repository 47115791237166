.grupo-box{
    display: flex;
    margin: 5px;


    .box-tela-comado{
        margin-right: 20px ;
        font-family: 'Gabarito', sans-serif;
        font-size: 15px;
        color: rgb(78, 78, 78);
    }
}