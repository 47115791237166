.sidebar {
  flex: 1;
  border-right: 0.5px solid rgb(233, 231, 231);
  /*min-width: 270px;
  min-height: 100vh;*/
  background-color: white;

  .top {
    height: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #FAD40C;
    background: #6B4380; 
  }


}
