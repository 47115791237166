.taable{
    width: 100%;
    background: #ffffff;
    padding: 0px 0px 0px 0px;
    margin: 5px;
    background-color: transparent;
    .head{
        font-family: 'Gabarito', sans-serif;
        font-size: 15px;
        color: rgb(78, 78, 78);
        background-color: transparent;
        .th{
            background-color: transparent;
            border-bottom: 1px solid #F2F2F2;
        }
    }
    .td{
        font-family: 'Gabarito', sans-serif;
        font-size: 15px;
        color: rgb(78, 78, 78);
        text-align: center;
        border-bottom: 1px solid #F2F2F2;
        .material-icons{
            color:  rgb(107, 67, 128)
        }
        .box{
            border-radius: 7px;
        }

    }
}