.popup-alert{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.788);

    display: flex;
    justify-content: center;
    align-items: center;

    .popup-inner-alert{
        display: flex;
        flex-direction: column;
        padding: 32px;
        width: 100%;
        max-width: 40%;
        height: auto;
        background-color: white;
        border-radius: 7px;
    
        .material-icons{
            height: auto;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
        }

        .conteudo-popUp{
            width: 100%;
            height: 100%;
            padding: 10px;
            display: flex;
            justify-content: center;

        }
        .botao-ok{
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}