
.menuContent{
  .typeOpts{
    display: flex;
    padding: 2px;
    label{
      padding-left: 5px;
    }
   
    &:hover{
      background-color: #d4d4d4;
    }
  }
  textarea{
    min-height: 20vh;
  }
}
.inputItem{
  display: flex;
  align-items: center;
  justify-content: center;
 
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;

  &:hover {
    background-color: #d4d4d4;
  }
  
}

.filtroOpts {
  position: absolute;
  top: 105px;
  display: flex;
  width: 120px;
  flex-direction: column;
  z-index: 98;
  .filterOption {
    overflow: hidden;
    position: relative;
    display: flex;
    padding: 2px 5px 2px 8px;
    margin-bottom: 2px;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(250, 250, 250);
    border: 1px solid rgb(122, 122, 122);
    font-size: 15px;
    z-index: 99;
    cursor: pointer;
    &:hover {
      border-color: rgb(10, 10, 10);
      i {
        color: rgb(38, 74, 165);
      }
    }
  }
  .checked {
    background-color: rgb(179, 179, 179);
  }
}
.autocomplete {
  position: relative;
  display: inline-block;
}

.autocomplete-input {
  width: 300px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

#autocomplete-list {
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: scroll;
}

.autocomplete-item {
  padding: 10px;
  font-size: 16px;
  color: #444;
  cursor: pointer;
}

.autocomplete-item:hover {
  background-color: #ddd;
}

.listItem{
  display: flex;
  align-items: center;
  margin: 5px 0px 5px 0px;
  padding: 5px;
  border: 1px solid rgb(179, 179, 179);
  cursor: pointer;
  .details{
    display: flex;
    flex-direction: column;
    #itemStatus{
      margin-left: 40px;
      font-size: 18px;
      font-style: bold;
    }
    .title{
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 0.5px solid rgba(179, 179, 179, 0.377);

      #date{
        margin-left: 15px;
      }
    }
  }
  .icons {
    padding: 0px 10px 0px 10px;
    margin-right: 5px;
  }
  &:hover {
    background-color: #d4d4d4;
  }
}
.menuItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;

  &:hover {
    background-color: #d8d8d8;
  }
}

.newKb {
  display: flex;
  margin: 10px;
  input,
  textarea {
    caret-color: black;
    outline: none;
  }
  .makeNew {
    display: flex;
    padding: 10px;
    border: 1px solid rgb(179, 179, 179);

    .inputs {
      display: flex;
      flex-direction: column;
      width: 400px;
    }
    .kbContent {
      height: 250px;
    }
  }
  .preview {
    margin-left: 10px;

    width: 100%;
    border: 1px solid rgb(179, 179, 179);
    textarea {
      border: none;
      width: 400px;
    }
  }
}
.kbList {
  display: flex;
  flex-direction: column;
  margin: 10px;

  .kbListItem {
    display: flex;
    align-items: center;
    margin: 5px 0px 5px 0px;
    padding: 5px;
    border: 1px solid rgb(179, 179, 179);
    cursor: pointer;

    .icons {
      padding: 0px 10px 0px 10px;
      margin-right: 5px;
    }
    &:hover {
      background-color: #d4d4d4;
    }
  }
}