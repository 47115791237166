.newLiveData {
  display: flex;
  .optList {
    display: flex;
    max-height: 600px;
    overflow-y: scroll;
    .janelaFiltro {
      display: flex;
      width: 200px;
      padding: 5px;
      border-radius: 5px;
      flex-direction: column;
      background-color: white;
      position: absolute;
      .filterOption {
        display: flex;
        justify-content: space-between;

        align-content: center;

        font-size: 15px;
      }
    }
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      max-width: 100%;
      white-space: nowrap;
    }

    td,
    th {
      border: 1px solid #b4b4b4;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #dddddd;
    }
    .icon {
      font-size: 25px;
    }
    .pending {
      color: yellow;
    }
    .arrow_circle_up {
      color: blue;
    }
    .remove_circle_outline {
      color: orange;
    }
    .check_circle_outline {
      color: green;
    }
  }
}  

@media (max-width: 1000px) {

  .chartDiv {
    flex-direction: column;
    .chartDiv.nameChart{
      align-items: flex-start;
  

    }

  }
}


.chartDiv{
  display: flex;
  position: relative;
  width: 100%;

  .nameChart{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;


    .imagens{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .menu-chart{
      width: 500px;
      display: flex;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 15px;
      align-items: center;

      .titleChart{
        color: rgb(78, 78, 78);
        width: 50%;
        display: flex;
        font-size: 20px;
        position: relative;
        justify-content: flex-start;
        font-weight: bold;
        border: 2px solid #efefef;
        border-radius: 7px;
        cursor: pointer;
      }
      .menu-date{  

      .none {
        display: none;
      }

      .buttons {
        flex: 1;
        display: flex;
        cursor: pointer;
        .hoje {
          position: absolute;
          width: 92px;
          font-size: 13px;
          top: 28px;
          left: -16px;
          text-decoration-line: underline;
          z-index: 99;
          &:hover {
            cursor: pointer;
  
            color:  #6b4380;
          }
        }
      }

      .menu-icons-center{
        display: flex;
        //align-items: flex-start;
        .icon {
          font-size: 27px;
          color: rgb(78, 78, 78);
          border: 2px solid #efefef;
          border-radius: 7px;
          &:hover {
            color:  #6b4380;
  
            text-shadow: 0px 1px 1px rgba(87, 87, 87, 0.6);
          }
        }
        .arrow {
          font-size: 25px;
  
          z-index: 99;
          cursor: pointer;
        }
  
        .datePicker {
          display: flex;
          align-items: center;
          position: relative;
          .pickers {
            position: absolute;
            width: 200px;
            top: 50px;
            left: -60px;
            padding: 8px;
            border-radius: 4px;
            display: flex;
            background-color: #dddddd;
            flex-direction: column;
            align-items: center;
            z-index: 999;
            .label {
              padding-right: 5px;
            }
            .newDates {
              display: flex;
              flex-direction: column;
  
              align-items: flex-end;
            }
            .dates {
              display: flex;
              padding: 2px;
            }
            .clickable:hover {
              cursor: pointer;
            }
          }
  
          .react-datepicker {
            display: flex;
          }
        }
      }
      }
      .right {
        display: flex;
        justify-content: center;
        font-weight: bold;
        padding-right: 2px;
        border: 2px solid #efefef;
        border-radius: 7px;
        height: 40px;
        width: 92px;
        .live {
          .buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
  
          &:hover {
            text-shadow: 0px 0px 2px rgba(100, 100, 100, 0.4);
          }
        }
        .metrics {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-left: 5px;
          span,
          p {
            white-space: nowrap;
          }
        }
        .dragable {
          background: rgba(1, 1, 1, 0.2);
          border-bottom: 1px solid black;
          border-right: 1px solid black;
          border-top: 1px solid black;
          cursor: col-resize;
          height: 20px;
          width: 10px;
        }
        .icon {
          font-size: 20px;
        }
        .ativo {
          color: #6b4380;
        }
        .inativo {
          color: #f4db61;
        }
      }
    }

    .uPlot-todos {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 10px;
      .u-wrap {
        order:1;
      }
      .u-legend {
        order:0;
        display: flex;
        flex-direction: row;

        font-size: 19px;
        tbody{
          display: flex;

        }
        .u-marker{
          width: 9px;
          height: 9px;
        }
        
        .u-value {
          font-size: 19px;
        }


        tr:nth-child(1) {
          order: 3;
          position: relative;
          top: -41px;
          left: -59px;
          width: 20px;
          margin-right: 2px;
          font-weight: bold;
          color: #6b4380;

          th:nth-child(1) {
            display: none;
          }
          
          .u-value {
            font-size: 10px;
            line-height: 10px;
          }
        }

        tr:nth-child(2) {
          order: 1;
          margin-right: 2px;
        }

        tr:nth-child(3) {
          display: none;
          order: 2;
        }

        tr:nth-child(4) {
          margin-right: 2px;
        }
        tr:nth-child(5) {
          margin-right: 2px;
        }

      }
    }
  }

}

.chart {
  display: flex;
  min-height: 500px;
  flex-direction: column;

  .listas{
    display: flex;
  }
}