.newLiveData {
  display: flex;
  .optList {
    display: flex;
    max-height: 600px;
    overflow-y: scroll;
    .janelaFiltro {
      display: flex;
      width: 200px;
      padding: 5px;
      border-radius: 5px;
      flex-direction: column;
      background-color: white;
      position: absolute;
      .filterOption {
        display: flex;
        justify-content: space-between;

        align-content: center;

        font-size: 15px;
      }
    }
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      max-width: 100%;
      white-space: nowrap;
    }

    td,
    th {
      border: 1px solid #b4b4b4;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #dddddd;
    }
    .icon {
      font-size: 25px;
    }
    .pending {
      color: yellow;
    }
    .arrow_circle_up {
      color: blue;
    }
    .remove_circle_outline {
      color: orange;
    }
    .check_circle_outline {
      color: green;
    }
  }
}
.conte-charts{
  display: flex;
  flex-direction: column;

  .conte-chart {
    display: flex;
    min-height: 500px;
    flex-direction: column;
    width: 100%;

    .menu-chart {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 13px;

      .conte-menu{
        display: flex;
        width: 870px;

        .none {
          display: none;
        }
        .buttons {
          flex: 1;
          display: flex;
          cursor: pointer;
          .hoje {
            position: absolute;
            width: 92px;
            font-size: 13px;
            top: 42px;
            left: -11px;
            text-decoration-line: underline;
            z-index: 99;
            &:hover {
              cursor: pointer;
    
              color: #5f3ed8;
            }
          }
        }
        .left {
          display: flex;
          width: 17%;
          align-items: flex-start;
          .icon {
            font-size: 35px;
            &:hover {
              color: #7451f8;
    
              text-shadow: 0px 1px 1px rgba(87, 87, 87, 0.6);
            }
          }
          .arrow {
            font-size: 25px;
    
            z-index: 99;
            cursor: pointer;
          }
    
          .datePicker {
            display: flex;
            align-items: center;
            position: relative;
            .pickers {
              position: absolute;
              width: 200px;
              top: 50px;
              left: -60px;
              padding: 8px;
              border-radius: 4px;
              display: flex;
              background-color: #dddddd;
              flex-direction: column;
              align-items: center;
              z-index: 999;
              .label {
                padding-right: 5px;
              }
              .newDates {
                display: flex;
                flex-direction: column;
    
                align-items: flex-end;
              }
              .dates {
                display: flex;
                padding: 2px;
              }
              .clickable:hover {
                cursor: pointer;
              }
            }
    
            .react-datepicker {
              display: flex;
            }
          }
        }
      }

      .middle {
        width: 50%;
        display: flex;
        text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.9);
        flex: 1;
        font-size: 30px;
        align-items: center;
        font-weight: bold;
        .media {
          width: 25%;
          height: 100%;
          .cont {
            font-size: 18px;

            .warning {
              position: absolute;
              background-color: red;
              width: 800px;
              height: 10px;
              top: -12px;
              left: -121px;
            }
            .none {
              display: none;
            }
            .test {
              position: inherit;
              transition: background-color 0.5s;
              height: 10px;
            }
          }
        }
        .title-chart{
          width: 26%;
          height: 100%;
          font-size: 18px;
        }

        .equipes {
          display: flex;
          height: 100%;
          font-size: 20px;
          justify-content: space-around;
          cursor: pointer;
          p:hover {
            border: 1px solid blue;
          }
          p {
            width: 100px;
          }
        }
      }

      .right {
        width: 10%;
        display: flex;
        font-weight: bold;
        flex-direction: row;
        .live {
          .buttons {
            flex-direction: row;
            align-items: center;
          }

          &:hover {
            text-shadow: 0px 0px 2px rgba(100, 100, 100, 0.4);
          }
        }
        .metrics {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-left: 5px;
          span,
          p {
            white-space: nowrap;
          }
        }
        .dragable {
          background: rgba(1, 1, 1, 0.2);
          border-bottom: 1px solid black;
          border-right: 1px solid black;
          border-top: 1px solid black;
          cursor: col-resize;
          height: 20px;
          width: 10px;
        }
        .icon {
          font-size: 35px;
        }
        .ativo {
          color: rgb(9, 141, 20);
        }
        .inativo {
          color: rgb(230, 0, 0);
        }
      }
    }
    .chartsView{
      display: flex;
      flex-direction: column;
      align-items: center;

      .uPlot-chart-maringa {
        display: flex;
        flex-direction: column;
        .u-wrap {
          order:1;
        }
        .u-legend {
          order:0;
          font-size: 15px;
          font-weight: 600;
          .u-marker {
            position: inherit;
          }
          .u-value {
            font-size: 35px;
          }

          tr:nth-child(1) {
            width: 10px;
            position: relative;
            top: 5px;
            left: 680px;
            font-size: 11px; 
            color: rgb(9, 141, 20);

            .u-value {
              font-size: 15px;
              line-height: 15px;
            }
            th:nth-child(1) {
              display: none;
            }
          }
        }
      }

      .menu-weather{
        height: 20px;
      }
      .weatherPlot {
        width: 100%;
        display: flex;
        flex-direction: column;

        .u-wrap {
          order:1;
        }

        .u-legend {
          order:0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
    
          .u-value {
            font-size: 20px;
          }
        }
      }
    }

    .weatherMenu {
      display: flex;
      font-size: 30px;
      font-weight: 500;
      .uPlot {
        .u-wrap {
          margin-top: 32px;
        }
      }
      .icon {
        font-size: 25px;
      }
      .temp {
        position: absolute;
        left: 789px;
        &:hover {
          color: #7451f8;

          text-shadow: 0px 1px 1px rgba(87, 87, 87, 0.3);
        }
      }
      .precipita {
        position: absolute;
        left: 662px;
        &:hover {
          color: #5f3ed8;

          text-shadow: 0px 1px 1px rgba(87, 87, 87, 0.3);
        }
      }
    }
  }

}
