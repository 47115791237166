.selectedItem {
  display: flex;
  border: 1px solid rgb(179, 179, 179);
  margin: 10px;

  .opts {
    display: flex;
    flex-direction: column;
    border-right: 0.5px solid rgb(179, 179, 179);
  }
  .data {
    padding: 5px;
    width: 100%;
    textarea {
      caret-color: black;
      outline: none;
      border: none;
      width: 100%;
      font-size: 16px;
      min-height: 30vh;
    }
    .inputs {
      display: flex;
      flex-direction: column;
      width: 400px;
    }
    
    .itemHeader {
      display: flex;
      align-items: center;
      padding: 5px;
      border-bottom: 1px solid rgb(179, 179, 179);
      #itemStatus{
        margin-left: 50px;
        font-size: 18px;
        font-style: bold;
      }
      &:hover {
        background-color: #e7e5e5;
      }
    }
  
    .kbContent {
      border-top: 0.5px solid rgb(179, 179, 179);
      border-bottom: 0.5px solid rgb(179, 179, 179);
      margin: 15px 0px 0px 0px;
      padding: 15px 0px 15px 0px;
    }
    .kbFooter{
      display: flex;
    }
  }
}

.multCheck{
  display: flex;
  label{
    padding: 2px;
    &:hover{
      background-color: #e7e5e5;
    }
  }
  label.checked{
    background-color: #df1414;

  }
}