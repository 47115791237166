.conhecimento {
  display: flex;
  width: 100%;

  .conhecimentoCont {
    flex: 6;

    .box {
      margin: 10px;
      align-items: center;
      border-radius: 2px;
      border: 1px solid rgb(179, 179, 179);

      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%);
    }
    
  }
}
