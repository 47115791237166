.div-tabela-comando-limite{
    margin-bottom: 15px;
    margin-right: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    border: 1px solid #BCB0B0;
    border-radius: 8px;

    .botao-atualizar{
       width: 100%;
       display: flex;
       justify-content: center;
   }


             
    .botoes-mode{
        width: 100%;
        .icon-status-ativo{
            cursor: pointer;
            padding: 3px;
            border:1px solid rgb(107, 67, 128);
            background-color: rgb(107, 67, 128);
            border-radius: 8px;
            color: white;
            margin-right: 5px;

        }
        .icon-status-inativo{
            cursor: pointer;
            padding: 3px;
            border:1px solid rgb(78, 78, 78);
            border-radius: 8px;
            color: rgb(78, 78, 78);

            margin-right: 5px;

        }
        .icon-carregando{
            cursor: pointer;
            padding: 3px;
            border:1px solid #f09226;
            background-color: #f09226;
            border-radius: 8px;
            color: white;
            margin-right: 5px;
        }
    }
 
    .loader-comando{
     top: 50%;
     width: 10%;
     height: 60px;
     justify-content: center;
     display: flex;
     background-color: rgba(248, 245, 245, 0.788);
     position: fixed;
     border-radius: 8px;
     }
 
 
    .table-tela-comando{
         border-spacing:  20px 8px;

         .cabecalho-comando{
             font-family: 'Gabarito', sans-serif;
             font-size: 17px;
             color: rgb(78, 78, 78);
 
             .tr{
                align-items: center;
                display: flex;
                justify-content: center;
                width: 100%;
                 .th{
                 }
             }
             
         }
 
         .tbody-tela-comando{

             .tr{

                align-items: center;
                display: flex;
                 
                 th:nth-child(1) {
                     font-family: 'Gabarito', sans-serif;
                     font-size: 17px;
                     color: rgb(78, 78, 78);
                     width: 75px;
                     height: auto;
                     align-items: flex-start;
                     justify-content: flex-start;
                     display: flex;
                     
                 }
                 .th{
                     color:  rgb(107, 67, 128);
                     cursor: pointer;
                     .icone-star{
                        font-size: 17px;
                        color:  #255290
                     }
                 }
             }
         }
    }
 }