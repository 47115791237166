.popup-retorno {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;

    .popup-inner-retorno {
        display: flex;
        flex-direction: column;
        padding: 32px;
        background-color: white;
        border-radius: 7px;
        max-width: fit-content; 
        .material-icons {
            align-self: flex-end;
            cursor: pointer;
        }

        .conteudo-popUp-retorno {
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 95%; 
        }

        .botao-ok-retorno {
            display: flex;
            justify-content: flex-end; 
            align-self: flex-end;
            margin-top: 10px;
        }
    }
}
