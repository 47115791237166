.div-tabela-comando{
   margin-bottom: 15px;
   justify-content: center;
   align-items: center;
   display: flex;

   .loader-comando{
    top: 50%;
    width: 10%;
    height: 60px;
    justify-content: center;
    display: flex;
    background-color: rgba(248, 245, 245, 0.788);
    position: fixed;
    border-radius: 8px;
    }


   .table-tela-comando{
        border-spacing:  20px 8px;
        border: 1px solid #BCB0B0;
        border-radius: 8px;
        
        .cabecalho-comando{
            font-family: 'Gabarito', sans-serif;
            font-size: 17px;
            color: rgb(78, 78, 78);

            .tr{
                .th{
                }
            }
            
        }

        .tbody-tela-comando{
            .tr{
                
                th:nth-child(1) {
                    font-family: 'Gabarito', sans-serif;
                    font-size: 17px;
                    color: rgb(78, 78, 78);
                    width: 150px;
                    align-items: flex-start;
                    justify-content: flex-start;
                    display: flex;
                    
                }
                .th{
                    color:  rgb(107, 67, 128);
                    cursor: pointer;
                }
            }

        }
   }
}