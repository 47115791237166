.financeira {
    display: flex;
    width: 100%;
  
    .financeiraCont {
      flex: 6;

      .container-financeira{
        margin: 10px 40px 10px 30px;

        .container{
          width: 100%;
          height: 15%;
          display: flex;
          //align-items: end; //centraliza horizontalmente
          //justify-content: center; //cetraliza verticalmente

          .components{

            margin-bottom: 25px;
            padding: 20px 30px 10px 30px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            //align-items: end; //centraliza horizontalmente
            //justify-content: center; //cetraliza verticalmente
            .button-upload{
              
              width: 20%;
              display: flex;
              justify-content: flex-end;
              align-items: center;

              .update-icon{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 34px ;
                width: 40px;
                background-color: rgb(78, 78, 78);
                border: 2px solid rgb(78, 78, 78);
                border-radius: 8px;
                margin-right: 5px;
                cursor: pointer;
                .icon-update{
                  color: #FEFCFF;
                }

              }

            }
          }
        }
      }

      .divTable{
        margin: 20px;
        margin-right: 30px;
      }
      .nova-cobranca{
        display: flex;
        flex-direction: column;

        .filter-menor{
          padding: 5px;
          color: rgb(78, 78, 78);
          display: flex ;
          .icones-form{
            margin-right: 20px;
          
          .datePicker-add {
            display: flex;
            align-items: center;
            padding: 10px;
            .newDates-add {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
                .date-clicavel-add{
                  cursor: pointer;
                  border-radius: 5px;
                  border: 1px solid #BCB0B0;
                  color: rgb(78, 78, 78);
    
                }
            }
          }
          }

        }
        .botao-aplicar{
          margin-top: 20px;
          cursor: pointer;
          border-radius: 5px;
          border: 1px solid #6B4380;
          background-color: #6B4380;
          color: #ffffff;
        }
      }
      .filter-mais{
        width: 500px;
        padding: 5px;
        color: rgb(78, 78, 78);
        justify-content: space-between;
        display: flex ;
        flex-direction: column;
        .icones-form{
          margin-bottom: 10px;
        }
        .date-form{
          margin-bottom: 25px;
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
        }
        .botao-aplicar{
          cursor: pointer;
          border-radius: 5px;
          border: 1px solid #6B4380;
          background-color: #6B4380;
          color: #ffffff;
        }
      }
      .title-filter-mais{
        padding-bottom: 15px;
        font-size: 30px;
        font-weight: bold;
        color: #6B4380;
      }
      .lista-PDF{
        margin: 15px;
        width: 100%;
        color: #166ce5;
      }

      .upload{
        display: flex;
        margin: 5px;
        margin-bottom: 15px;
        .placeholder{
          background: #FEFCFF;
          border: 1px solid #BCB0B0;
          border-radius: 7px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          padding: 5px;
          height: 20px ;
          width: 400px;
        }
        .file-selection{
          background: #FEFCFF;
          border: 1px solid #BCB0B0;
          border-radius: 7px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          padding: 5px;
          height: 20px ;
          width: 100px;
          background-color: #e3e3e3;

          .file-selection-input{
            display: block;
            position: absolute;
            top: 40px;
            opacity: 0;
            cursor: pointer;
      
          }
        }
      }
      .loader{
        top: 50%;
        left: 0;
        width: 100%;
        height: 70px;
        justify-content: center;
        display: flex;
        position: fixed;
      }
    
      .buttons{
        margin-right: 10px;
        display: flex;
        justify-content: flex-end; //cetraliza verticalmente
      }
    }
  }
  