.conteudo-file-Upload{
  margin-top: 10px;
  display:flex;
  flex-direction: column;
  align-items: center;

  .legenda-tabela{
    display: flex;
    align-items: center;
    margin: 10px;
    font-weight: bold;
    font-size: 15px;
    color: rgb(78, 78, 78);

    .icon-update{
      color: #6B4380;;
    }
  }
  .tabela-upload{
    margin-left: 15px;
    border: 1px solid #BCB0B0;
    border-radius: 5px;
    padding: 5px;
  }

  .upload{
      display: flex;
      margin: 5px;
      margin-bottom: 20px;
      .placeholder{
        background: #FEFCFF;
        border: 1px solid #BCB0B0;
        border-radius: 7px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 5px;
        height: 20px ;
        width: 400px;
      }
      .file-selection{
        background: #FEFCFF;
        border: 1px solid #BCB0B0;
        border-radius: 7px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        padding: 5px;
        height: 20px ;
        width: 100px;
        background-color: #e3e3e3;
        .file-selection-input{
          display: block;
          position: absolute;
          top: 40px;
          opacity: 0;
          cursor: pointer;
    
        }
      }
  }
}