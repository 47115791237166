.popup {
    position: fixed;
    top: 0;
    left: 0;
    height:100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.788);

    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;

    z-index: 199999;

    .popup-inner{
        position: relative;
        padding: 32px;
        max-width: 1040px;
        max-height: 850px;
        background-color: white;
        border-radius: 7px;
        overflow-x: auto;
        .icone-popup-fechar{
            position: absolute;
            top: 16px;
            right: 16px;
            cursor: pointer;
        }
    }
}