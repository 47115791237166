
.tabela-entregas{
    //width: 100%;
    margin: 15px;
    .itens::-webkit-scrollbar{width:5px; background-color: #D0D4CE; height: 5px; }
    .itens::-webkit-scrollbar-thumb{
        border-radius:5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #71806B;
    }
    
    .titulo-entregas{
        display: flex;
        justify-content: center;
        color: rgb(78, 78, 78);
        font-weight: bold;
    }
    .corpo-tabela{
        //border: 2px solid #efefef;
        border-radius: 4px;
        border: 2px solid #efefef;
        .cabeca{
            display: flex; 
            flex-direction: column;
            color: rgb(78, 78, 78);
            width: 100%;
            tr{
                display: flex; 
                padding: 5px 5px 5px 5px;
                border-bottom: 2px solid #efefef;
                th:nth-child(1) {
                    width: 150px;
                }            
                th:nth-child(2) {
                    width: 50px;
                    justify-content: center;
                    display: flex;
                }
                th:nth-child(3) {
                    width: 50px;
                    justify-content: center;
                    display: flex;
                }
            }
        }
        .itens{
            max-height: 300px;
            overflow: auto;
            color:rgb(72, 71, 71);
            display: flex;
            flex-direction: column; 

            
            tr{
                padding: 5px 5px 5px 0px;
                display: flex; 
                width: 100%;
                border-bottom: 2px solid #efefef;
            }
            td{
                padding: 5px 5px 5px 2px;
                justify-content: center

            }
            td:nth-child(1) {
                width: 150px;
            }            
            td:nth-child(2) {
                width: 50px;
                justify-content: center;
                display: flex;
            }
            td:nth-child(3) {
                width: 50px;
                justify-content: center;
                display: flex;
            }

        }
    }
    
}
