.map {
  display: flex;
  width: 100%;

  .mapCont {
    flex: 6;

    #mapId {
      width: 700px;
      height: 400px;
      border: 1px solid blue;
    }
  }
}
