
.select-check {
    margin: 5px;
    .check-select{
       

    }
    .css-b62m3t-container{
        color: rgb(78, 78, 78);
        :hover{
            
        }
        .css-1nmdi-menu{
           
        
        }
    }

    .place{
        background: transparent;
        border: none;
        width: 100%;
        height: 100%;
        font-size: 15px;
        color: rgb(78, 78, 78);
        cursor: pointer;
    }
}